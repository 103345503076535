"use strict";

import $ from "jquery";
import Techo from "./client";

$(function () {
  $('body').on('click', '.notification-btn', async function(){
    // 既読通知
    await Techo.updateNotificationRead();
    var badge_elem = $(".notification-badge");
    if (badge_elem) {
      badge_elem.remove();
    }
    return false;
  });
});
