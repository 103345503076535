import $ from "jquery";
import swal from "sweetalert";
import Techo from "../../javascripts/mypage/client";
import '../../javascripts/vendors/scripts/intro_customize'


$(function () {
  // FYI: https://sisdev.backlog.jp/view/NPB_DEV-1747
  // 新規登録後初めてチュートリアルを閉じるときにリロードさせて、別ページからブラウザバックした時に表示させないようにする
  $(document).on('click', '.modal-overlay, .modal-close, .get-started', function() {
    if($(this).parents('#intro').length && $('#finished_tutorial').val() == 'false') {
      window.location.reload();
    }
  });

  $(".get-started").on("click", function () {
    Techo.fetchPlayerFinishedTutorial({})
      .catch((err) => {
        console.log("err:", err);
        swal({ title: "更新に失敗しました", icon: "error" });
      });
  })
})
