'use strict';

import '../../stylesheets/mypage/mypage';
import '../../javascripts/mypage/intro_base.js';
import '../../javascripts/mypage/notification.js';

import $ from "jquery";
import swal from "sweetalert";

import Rails from '@rails/ujs'
Rails.start();

$(function () {
  $(".waves-effect").on("click", function () {
    $('.waves-ripple').css('opacity', 0)
    $('.waves-ripple').css({
      'transitionProperty': 'opacity',
      'transitionDuration': '3s',
      'transitionDelay': '0.5s'
    })
  });

  // 二重クリック防止
  // required属性には対応しているが、js側で独自でバリデーションを設けている場合は、個々に対応が必要
  $('body').on('click', '.disable-double-click', function(){
    const $this = $(this);
    const $form = $this.closest('form');

    $this.addClass('disabled');
    if ($form) {
      // requiredに引っかかれば、ボタンを活性
      if (!$form.get(0).reportValidity()) {
        $this.removeClass('disabled');
      }
    }
  } );

  // アラートの表示
  function alert() {
    const origin_message = $('#messages-alert').val()
    const message = origin_message ? origin_message.replace('\\n', '\n') : null
    const notice = $('#messages-notice').val()
    if (message) {
      swal(message, '', notice);
      // 表示リセット
      $('#messages-alert').val(undefined)
      $('#messages-notice').val(undefined)
    }
  }

  alert();
})
