import { TechoApi } from "./api";
import M from "materialize-css";
import $ from "jquery";

//----------------------------------------------
// APIチーム
//----------------------------------------------
// TODO:watanabe みにくいので整理する
// 試合結果 Api::V1::Mypage::Teams::ScoresController
const fetchTeamScore = function (team_id, search_params) {
  var url = URLJoin("teams", team_id, "scores");
  return TechoApi.get(url, { params: search_params });
}

const deleteTeamScores = function (team_id, game_id) {
  var url = URLJoin("teams", team_id, "scores", game_id);
  return TechoApi.delete(url);
}
//----------------------------------------------
// API個人ノート
//----------------------------------------------
// NPB個人成績（打撃）
const fetchProfessionalBatterScores = function () {
  var url = URLJoin('players', 'professional_batter_scores');
  return TechoApi.get(url);
};

// NPB個人成績（投手）
const fetchProfessionalPitcherScores = function () {
  var url = URLJoin('players', 'professional_pitcher_scores');
  return TechoApi.get(url);
};


// 課題項目 Api::V1::Mypage::Players::PersonalPracticesController
const deletePlayerPersonalPractices = function (personal_practice_id) {
  var url = URLJoin('players', 'personal_practices', personal_practice_id);
  return TechoApi.delete(url);
};

const createPlayerPersonalPractices = function (create_params) {
  var url = URLJoin('players', 'personal_practices');
  return TechoApi.post(url, create_params);
};

const editPlayerPersonalPractices = function (personal_practice_id) {
  var url = URLJoin('players', 'personal_practices', personal_practice_id, 'edit');
  return TechoApi.get(url);
};

const updatePlayerPersonalPractices = function (personal_practice_id, update_params) {
  var url = URLJoin('players', 'personal_practices', personal_practice_id);
  return TechoApi.patch(url, update_params);
};

// 体力テスト Api::V1::Mypage::Players::PhysicalTests::PhysicalFitnessScoreController
const editPlayerPhysicalFitnessScores = function (physical_fitness_score_id) {
  var url = URLJoin('players', 'physical_tests', 'physical_fitness_scores', physical_fitness_score_id, 'edit');
  return TechoApi.get(url);
};

const updatePlayerPhysicalFitnessScores = function (physical_fitness_score_id, update_params) {
  var url = URLJoin('players', 'physical_tests', 'physical_fitness_scores', physical_fitness_score_id);
  return TechoApi.patch(url, update_params);
};

const createPlayerPhysicalFitnessScores = function (create_params) {
  var url = URLJoin('players', 'physical_tests', 'physical_fitness_scores');
  return TechoApi.post(url, create_params);
};

const deletePlayerPhysicalFitnessScores = function (physical_fitness_score_id) {
  var url = URLJoin('players', 'physical_tests', 'physical_fitness_scores', physical_fitness_score_id);
  return TechoApi.delete(url);
};

// 野球技能テスト Api::V1::Mypage::Players::PhysicalTests::BaseballSkillScoresController
const editPlayerBaseballSkillScores = function (baseball_skill_score_id) {
  var url = URLJoin('players', 'physical_tests', 'baseball_skill_scores', baseball_skill_score_id, 'edit');
  return TechoApi.get(url);
};

const updatePlayerBaseballSkillScores = function (baseball_skill_score_id, update_params) {
  var url = URLJoin('players', 'physical_tests', 'baseball_skill_scores', baseball_skill_score_id);
  return TechoApi.patch(url, update_params);
};

const createPlayerBaseballSkillScores = function (create_params) {
  var url = URLJoin('players', 'physical_tests', 'baseball_skill_scores');
  return TechoApi.post(url, create_params);
};

const deletePlayerBaseballSkillScores = function (baseball_skill_score_id) {
  var url = URLJoin('players', 'physical_tests', 'baseball_skill_scores', baseball_skill_score_id);
  return TechoApi.delete(url);
}

// 課題結果 Api::V1::Mypage::Players::PersonalPractices::PersonalPracticeResultsController
const createPlayerPersonalPracticeResults = function (personal_practice_id, create_params) {
  var url = URLJoin('players', 'personal_practices', personal_practice_id, 'results');
  return TechoApi.post(url, create_params);
};

const editPlayerPersonalPracticeResults = function (personal_practice_id, result_id) {
  var url = URLJoin('players', 'personal_practices', personal_practice_id, 'results', result_id, 'edit');
  return TechoApi.get(url);
};

const updatePlayerPersonalPracticeResults = function (personal_practice_id, result_id, update_params) {
  var url = URLJoin('players', 'personal_practices', personal_practice_id, 'results', result_id);
  return TechoApi.patch(url, update_params);
};

const deletePlayerPersonalPracticeResults = function (personal_practice_id, result_id) {
  var url = URLJoin('players', 'personal_practices', personal_practice_id, 'results', result_id);
  return TechoApi.delete(url);
};

// 試合結果 Api::V1::Mypage::Players::ScoresController
const fetchPlayerScores = function (search_params) {
  var url = URLJoin('players', 'scores');
  return TechoApi.get(url, { params: search_params });
};

const deletePlayerScores = function (game_id) {
  var url = URLJoin('players', 'scores', game_id);
  return TechoApi.delete(url);
};

// 個人の日誌一覧を取得
const fetchPlayerStats = function (tabs, search_params) {
  var url = URLJoin('players', 'stats', tabs);
  return TechoApi.get(url, { params: search_params });
};

// チーム連絡帳一覧を取得
const fetchPlayerContacts = function (search_params) {
  var url = URLJoin('players', 'contacts');
  return TechoApi.get(url, { params: search_params });
};

// チーム連絡帳を登録
const createPlayerContacts = function (params) {
  var url = URLJoin('players', 'contacts');
  return TechoApi.post(url, params);
};

// 練習記録を取得
const fetchPlayerPersonalPracticeResults = function (search_params) {
  var url = URLJoin('players', 'personal_practice_results');
  return TechoApi.get(url, { params: search_params });
};
// 練習記録を取得
const fetchPlayerFinishedTutorial = function () {
  var url = URLJoin('players', 'finished_tutorial');
  return TechoApi.patch(url, {});
};

// 目標一覧を取得
const fetchPlayerGoals = function (search_params) {
  var url = URLJoin('players', 'goals');
  return TechoApi.get(url, { params: search_params });
};

// 目標を作成
const createPlayerGoal = function (update_params) {
  var url = URLJoin('players', 'goals');
  return TechoApi.post(url, update_params);
};
// 目標を更新
const updatePlayerGoal = function (goal_id, update_params) {
  var url = URLJoin('players', 'goals', goal_id);
  return TechoApi.patch(url, update_params);
};
// 目標を削除
const deletePlayerGoal = function (goal_id) {
  var url = URLJoin('players', 'goals', goal_id);
  return TechoApi.delete(url);
};
// 目標取得
const showPlayerGoal = function (goal_id) {
  var url = URLJoin('players', 'goals', goal_id);
  return TechoApi.get(url);
}

// 個人の日誌一覧を取得
const fetchPlayerNotes = function (search_params) {
  var url = URLJoin("notes");
  return TechoApi.get(url, { params: search_params });
};

// 個人ダッシュボードの日誌をつけた日数
const fetchPlayerDashboardNotes = function (search_params) {
  var url = URLJoin('players', 'notes');
  return TechoApi.get(url, { params: search_params });
};

// チーム側日誌一覧を取得
const fetchTeamNotes = function (team_id, search_params) {
  var url = URLJoin("teams", team_id, "notes");
  return TechoApi.get(url, { params: search_params });
}

// 指定IDの日誌を削除
const deletePlayerNote = function (note_id) {
  var url = URLJoin("notes", note_id);
  return TechoApi.delete(url);
};

// 指定IDのいいね一覧を取得
const fetchNoteFavorites = function (note_id) {
  var url = URLJoin("notes", note_id, "favorites");
  return TechoApi.get(url);
};

// いいね切り替え
const updateFavorites = function (note_id) {
  var url = URLJoin("notes", note_id, "favorites");
  return TechoApi.post(url);
}

// 個人日誌（新規）
const createNote = function (params) {
  var url = URLJoin("notes");
  return TechoApi.post(url, params);
}

// 個人日誌（編集データ取得）
const editNote = function (note_id) {
  var url = URLJoin("notes", note_id, "edit");
  return TechoApi.get(url);
}

// 個人日誌（編集）
const updateNote = function (note_id, params) {
  var url = URLJoin("notes", note_id);
  return TechoApi.post(url, params);
}

// コメント投稿
const createComment = function (note_id, params) {
  var url = URLJoin("notes", note_id, 'comments');
  return TechoApi.post(url, params);
}

// コメント削除
const deleteComment = function (note_id, comment_id) {
  var url = URLJoin('notes', note_id, 'comments', comment_id);
  return TechoApi.delete(url);
}

// チーム掲示板一覧
const fetchTeamBoards = function (team_id, params) {
  var url = URLJoin("teams", team_id, "boards")
  return TechoApi.get(url, { params: params });
}

// 打撃成績（新規）
const createBattingScore = function (team_id, params) {
  // BattingScores
  var url = URLJoin("teams", team_id, "scores/batting_scores")
  return TechoApi.post(url, params);
}

// 打撃成績削除
const deleteBatterScore = function (team_id, batter_score_id) {
  var url = URLJoin("teams", team_id, "scores/batting_scores", batter_score_id)
  return TechoApi.delete(url);
}

// バッター成績取得
const BattingScore = function (team_id, game_id) {
  var url = URLJoin("teams", team_id, "scores", game_id, "batting_scores")
  return TechoApi.get(url);
}

// バッテリー成績取得
const BatteryScore = function (team_id, game_id) {
  var url = URLJoin("teams", team_id, "scores", game_id, "battery_scores")
  return TechoApi.get(url);
}
// バッテリー成績（新規）
const createBatteryScore = function (team_id, params) {
  var url = URLJoin("teams", team_id, "scores/battery_scores")
  return TechoApi.post(url, params);
}

// バッテリー成績削除
const deleteBatteryScore = function (team_id, battery_score_id) {
  var url = URLJoin("teams", team_id, "scores/battery_scores", battery_score_id)
  return TechoApi.delete(url);
}

// 指定掲示板のいいね一覧を取得
const fetchTeamBoardFavorites = function (team_id, board_id) {
  var url = URLJoin("teams", team_id, "boards", board_id, "favorites");
  return TechoApi.get(url);
};
// 掲示板いいね切り替え
const updateTeamBoardFavorites = function (team_id, board_id) {
  var url = URLJoin("teams", team_id, "boards", board_id, "favorites");
  return TechoApi.post(url);
}
// 掲示板新規投稿
const createTeamBoard = function (team_id, params) {
  var url = URLJoin("teams", team_id, "boards");
  return TechoApi.post(url, params);
}
// 掲示板編集データ取得
const editTeamBoard = function (team_id, board_id) {
  var url = URLJoin("teams", team_id, "boards", board_id, "edit");
  return TechoApi.get(url);
}
// 掲示板更新
const updateTeamBoard = function (team_id, board_id, params) {
  var url = URLJoin("teams", team_id, "boards", board_id);
  return TechoApi.post(url, params);
}
// 掲示板削除
const deleteTeamBoard = function (team_id, board_id) {
  var url = URLJoin("teams", team_id, "boards", board_id);
  return TechoApi.delete(url);
}

// 掲示板コメント投稿
const createTeamBoardComment = function (team_id, board_id, params) {
  var url = URLJoin("teams", team_id, "boards", board_id, 'comments');
  return TechoApi.post(url, params);
}
// 掲示板コメント削除
const deleteTeamBoardComment = function (team_id, board_id, comment_id) {
  var url = URLJoin('teams', team_id, "boards", board_id, 'comments', comment_id);
  return TechoApi.delete(url);
}
// 代行権限の変更
const changePermission = function (team_id, params) {
  var url = URLJoin("teams", team_id, "permissions", "change");
  return TechoApi.post(url, params);
}
// 通知既読時間の更新
const updateNotificationRead = function () {
  var url = URLJoin("players", "notifications");
  return TechoApi.post(url);
}
// 退会
const deleteAccount = function () {
  var url = URLJoin("players", "profiles", "remove");
  return TechoApi.delete(url);
}

// チームスケジュール登録
const createTeamSchedule = function (team_id, params) {
  var url = URLJoin("teams", team_id, "schedules");
  return TechoApi.post(url, params);
}
// チームスケジュール登録
const updateTeamSchedule = function (team_id, schedule_id, params) {
  var url = URLJoin("teams", team_id, "schedules", schedule_id);
  return TechoApi.patch(url, params);
}

// チーム成績を取得
const fetchTeamsStats = function (team_id, tabs, search_params) {
  var url = URLJoin('teams', team_id, 'stats', tabs);
  return TechoApi.get(url, { params: search_params });
};

// チームスケジュールを取得
const fetchTeamsSchedule = function (team_id, schedule_id, search_params) {
  var url = URLJoin('teams', team_id, 'schedules', schedule_id);
  return TechoApi.get(url, { params: search_params });
}

// チームスケジュールを取得
const fetchTeamsSchedules = function (team_id, search_params) {
  var url = URLJoin('teams', team_id, 'schedules');
  return TechoApi.get(url, { params: search_params });
}
// 日別チームスケジュールを取得
const fetchTeamsSchedulesByDay = function (team_id, search_params) {
  var url = URLJoin('teams', team_id, 'schedules', 'day');
  return TechoApi.get(url, { params: search_params });
}
// 月別チームスケジュールを取得
const fetchTeamsSchedulesByMonth = function (team_id, search_params) {
  var url = URLJoin('teams', team_id, 'schedules', 'month');
  return TechoApi.get(url, { params: search_params });
}
// 未回答チームスケジュールを取得
const fetchTeamsSchedulesByUnanswered = function (team_id, search_params) {
  var url = URLJoin('teams', team_id, 'schedules', 'unanswered');
  return TechoApi.get(url, { params: search_params });
}
// 出席返答済チームスケジュールを取得
const fetchTeamsSchedulesByAttendance = function (team_id, search_params) {
  var url = URLJoin('teams', team_id, 'schedules', 'attendance');
  return TechoApi.get(url, { params: search_params });
}
// 欠席返答済チームスケジュールを取得
const fetchTeamsSchedulesByAbsence = function (team_id, search_params) {
  var url = URLJoin('teams', team_id, 'schedules', 'absence');
  return TechoApi.get(url, { params: search_params });
}

// チームスケジュールを削除
const deleteTeamSchedule = function (team_id, schedule_id) {
  var url = URLJoin('teams', team_id, 'schedules', schedule_id);
  return TechoApi.delete(url, {});
};

// チームスケジュールへの出席/欠席を登録
const createTeamsScheduleAnswer = function (team_id, schedule_id, params) {
  var url = URLJoin('teams', team_id, 'schedules', schedule_id, 'answers');
  return TechoApi.post(url, params);
};

// チームスケジュールへの回答を未回答へ戻す
const deleteTeamsScheduleAnswer = function (team_id, schedule_id, answer_id) {
  var url = URLJoin('teams', team_id, 'schedules', schedule_id, 'answers', answer_id);
  return TechoApi.delete(url, {});
};

// チーム情報の更新
const updateTeamProfile = function (params) {
  var url = URLJoin("teams", "profiles", "update");
  return TechoApi.post(url, params);
}


// URLを安全に結合(Private)
const URLJoin = (...args) =>
  args
    .join("/")
    .replace(/(\/)+/g, "/")
    .replace(/^(.+):\//, "$1://")
    .replace(/^file:/, "file:/")
    .replace(/\/(\?|&|#[^!])/g, "$1")
    .replace(/\?/g, "&")
    .replace("&", "?");

// JSON Parse
function parseJson(data) {
  var json = {};
  for (var idx = 0; idx < data.length; idx++) {
    json[data[idx].name] = data[idx].value;
  }
  return json;
}

function imageThumbnailParams() {
  return {
    width: 400,
    height: 300,
    allow_content_type: new RegExp("image/.+"),
  }
}

//----------------------------------------------
// Materialize 操作
//----------------------------------------------

// 指定Elmentのモーダルを開きます
const modalOpen = function (element, dismissible = true) {
  M.Modal.getInstance(element, { dismissible: dismissible }).open();
};
// 指定Elementのモーダルを閉じます
const modalClose = function (element) {
  M.Modal.getInstance(element).close();
};

// DatePickerの日付をAPI用にフォーマットします
const getPickerDate = function (element) {
  try {
    const d = M.Datepicker.getInstance(element).date;

    const year = d.getFullYear();
    const month = 1 + d.getMonth();
    const month2 = month < 10 ? "0" + month : month;
    const date = d.getDate();
    const date2 = date < 10 ? "0" + date : date;

    return `${year}-${month2}-${date2}`;
  } catch (error) {
    //console.log(error);
    return "";
  }
};

// 指定ElementのDropdownを初期化します
const initDropDown = function (element) {
  M.Dropdown.init(element);
};

const materializeInit = function () {
  M.AutoInit();
}

// 指定ElementのDate Pickerを初期化します
const initDatePicker = function (element, default_date = null) {
  var options = {
    autoClose: true,
    format: 'yyyy年mm月dd日',
    container: 'body',
    i18n: {
      months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      monthsShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
      weekdaysShort: ['（日）', '（月）', '（火）', '（水）', '（木）', '（金）', '（土）'],
      weekdaysAbbrev: ['日', '月', '火', '水', '木', '金', '土'],
      done: '実行',
      cancel: 'キャンセル'
    },
    onDraw: function onDraw() {
      // materialize select dropdown not proper working on mobile and tablets so we make it browser default select
      $('.datepicker-container').find('.datepicker-select').addClass('browser-default');
      $(".datepicker-container .select-dropdown.dropdown-trigger").remove();
    }
  };

  if (default_date) options.default_date = default_date;

  M.Datepicker.init(element, options);
};

// Materializeのセレクトフォームを動的に選択します
// selector: selectフォームの要素
// value: 選択済みにする値
const formSelected = function (selector, value) {
  // clear
  var wrapper = selector.val(value).closest('.select-wrapper')
  wrapper.find('li').removeClass("active").removeClass("selected");

  // set value
  var dropdown = wrapper.find('.select-dropdown')
  var select_name = '';
  if (value) {
    select_name = selector.find(`[value="${value}"]`).html();
  } else {
    select_name = selector.find('option').first().html();
  }
  dropdown.val(select_name).find('span:contains(' + select_name + ')').parent().addClass('selected active');
}

// 指定ElementのTime Pickerを初期化します
const initTimePicker = function (element) {
  var options = {
    autoClose: true,
    format: 'hh:MM',
    twelveHour: false,
    container: 'body',
    i18n: {
      done: '実行',
      cancel: 'キャンセル'
    },
    onDraw: function onDraw() {
      $('.timepicker-container').find('.timepicker-select').addClass('browser-default');
      $(".timepicker-container .select-dropdown.dropdown-trigger").remove();
    }
  };
  M.Timepicker.init(element, options);
};

export default {
  fetchProfessionalBatterScores,
  fetchProfessionalPitcherScores,
  editPlayerPhysicalFitnessScores,
  updatePlayerPhysicalFitnessScores,
  createPlayerPhysicalFitnessScores,
  deletePlayerPhysicalFitnessScores,
  editPlayerBaseballSkillScores,
  updatePlayerBaseballSkillScores,
  createPlayerBaseballSkillScores,
  deletePlayerBaseballSkillScores,
  deletePlayerPersonalPractices,
  createPlayerPersonalPractices,
  editPlayerPersonalPractices,
  updatePlayerPersonalPractices,
  createPlayerPersonalPracticeResults,
  editPlayerPersonalPracticeResults,
  updatePlayerPersonalPracticeResults,
  deletePlayerPersonalPracticeResults,
  fetchPlayerContacts,
  createPlayerContacts,
  fetchPlayerStats,
  fetchPlayerPersonalPracticeResults,
  fetchPlayerFinishedTutorial,
  fetchPlayerScores,
  deletePlayerScores,
  materializeInit,
  modalOpen,
  modalClose,
  parseJson,
  imageThumbnailParams,
  createNote,
  editNote,
  createComment,
  deleteComment,
  updateNote,
  getPickerDate,
  initDatePicker,
  initTimePicker,
  initDropDown,
  fetchPlayerGoals,
  createPlayerGoal,
  updatePlayerGoal,
  deletePlayerGoal,
  showPlayerGoal,
  fetchPlayerNotes,
  fetchTeamNotes,
  createTeamSchedule,
  updateTeamSchedule,
  deletePlayerNote,
  fetchNoteFavorites,
  updateFavorites,
  fetchTeamBoards,
  fetchTeamBoardFavorites,
  updateTeamBoardFavorites,
  createTeamBoard,
  editTeamBoard,
  updateTeamBoard,
  deleteTeamBoard,
  createTeamBoardComment,
  deleteTeamBoardComment,
  BattingScore,
  BatteryScore,
  createBattingScore,
  deleteBatterScore,
  createBatteryScore,
  deleteBatteryScore,
  changePermission,
  updateNotificationRead,
  deleteAccount,
  formSelected,
  fetchTeamScore,
  deleteTeamScores,
  fetchTeamsStats,
  fetchTeamsSchedule,
  fetchTeamsSchedules,
  fetchTeamsSchedulesByDay,
  fetchTeamsSchedulesByMonth,
  fetchTeamsSchedulesByUnanswered,
  fetchTeamsSchedulesByAttendance,
  fetchTeamsSchedulesByAbsence,
  deleteTeamSchedule,
  createTeamsScheduleAnswer,
  deleteTeamsScheduleAnswer,
  updateTeamProfile,
  fetchPlayerDashboardNotes
};

