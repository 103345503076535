import Techo from "../../mypage/client";

$(window).on("load",
  function () {
    initCarousel();

    if ($('#finished_tutorial').val() == 'false') {
      setTimeout(function () {
        $("#img-modal").modal("open");
      },
        1800
      );
      Techo.fetchPlayerFinishedTutorial({})
        .catch((err) => {
          console.log("err:", err);
          swal({ title: "更新に失敗しました", icon: "error" });
        });
    }
  }
);

$(function () {
  // チュートリアル表示ボタン
  $('.show-tutorial').on('click', function () {
    initCarousel();
    Techo.modalOpen($("#img-modal"));

    return false;
  })

  $(".btn-next").on("click", function () {
    $(".intro-carousel").carousel("next");
    return false;
  });

  $(".btn-prev").on("click", function () {
    $(".intro-carousel").carousel("prev");
    return false;
  });
})

function cycle() {
  (1 == $(".carousel-item.active").index()) ?
    ($(".btn-prev").addClass("disabled")) :
    (1 < $(".carousel-item.active").index() && ($(".btn-prev").removeClass("disabled"),
      $(".btn-next").removeClass("disabled"),
      5 == $(".carousel-item.active").index() && $(".btn-next").addClass("disabled")))
}

function initCarousel() {
  $(".modal").modal(
    {
      onOpenEnd: function () {
        $(".carousel.carousel-slider").carousel({
          fullWidth: !0,
          indicators: !0,
          onCycleTo: cycle()
        })
      }
    }
  );
}
