import Axios from "axios";

export const TechoApi = createAxiosInstance();
export const TechoFileApi = createAxiosInstance(true);

function createAxiosInstance(multipart) {
  const api = Axios.create({
    baseURL: "/api/v1/mypage",
    headers: {
      ContentType: "application/json",
      "X-Requested-With":
        multipart == true ? "multipart/form-data" : "XMLHttpRequest",
    },
    responseType: "json",
  });

  // リクエスト共通処理
  api.interceptors.request.use(
    (config) => {
      const methods = ["post", "put", "patch", "delete"];
      const token = document
        .getElementsByName("csrf-token")[0]
        .getAttribute("content");
      if (methods.includes(config.method)) {
        config.headers["X-CSRF-Token"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // レスポンス共通処理
  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        window.location.href = "/players/sign_in";
      }
      return Promise.reject(error);
    }
  );

  return api;
}
